import { React, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CarouselComponent from "./CarouselComponent";
import "./Homepage.css";
import "./Layout.css";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Homepage = () => {
  const nav = useNavigate();

  const navToCheckout = () => {
    nav("/bestall");
  };

  return (
    <div className="homepage-container">
      <img
        className="start-image"
        src={require("../media/images/Överraskad.jpg")}
        alt="Nisseprat"
      ></img>
      <div className="start-image-text">
        <img
          className="start-image-text-item nisseprat-logo"
          src={require("../media/images/Nisseprat.png")}
          alt="Nisseprat logga"
        ></img>
        <p className="start-image-text-item">
          Väcker tomtenissen till liv och gör barnens väntan på jul betydligt
          roligare
        </p>
      </div>
      <div className="text-container">
        <h1 className="text-header">Så här säger tidigare kunder</h1>
        <CarouselComponent />
      </div>
      <div className="about-container">
        <div className="text-container">
          <h1 className="text-header">Om Nisseprat</h1>
          <p className="text-item">
            Många hem har numera en liten nissedörr uppsatt under december
            månad. Nisseprat är ett färdigt koncept till nissedörren som
            förstärker magin kring nissens hembesök ytterligare. I stället för
            brev som föräldrarna läser upp kommer tomtenissen Greta med en
            ljudhälsning till barnen varje dag. I hälsningarna ger Greta en hint
            om buset hon hittat på under natten eller ger barnen ett litet
            enkelt uppdrag.
          </p>
          <p className="text-item">
            Förutom de 24 ljudhälsningarna från tomtenissen medföljer även en
            pdf. Där får du som förälder överblick över ljudfilernas innehåll
            och information om eventuella förberedelser som krävs av dig. Alla
            bus och uppdrag är <b>väldigt</b> enkla att genomföra och i pdf-en
            erbjuds ofta olika alternativ så att du själv kan välja
            ambitionsnivå.
          </p>
          <p className="text-item">
            Det är alltså bara att följa instruktionerna i pdf-en dag för dag,
            spela upp ljudfilerna och låta nissemagin flöda.
          </p>
          <button className="btn-to-checkout" onClick={navToCheckout}>
            BESTÄLL HÄR
          </button>
        </div>
        <img
          src={require("../media/images/Nissebus.jpg")}
          alt="Nissedörr"
          className="door-image"
        ></img>
      </div>
    </div>
  );
};

export default Homepage;
