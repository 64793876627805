import React from "react";
import "./ExampleBox.css";
import "./Layout.css";
import "./Homepage.css";

const ExampleBox = () => {
  return (
    <>
      <div className="arets-nisse-container">
        <div className="text-container" style={{ paddingBottom: "40px" }}>
          <a className="text-header">Årets tomtenisse</a>
          <p className="text-item arets-nisse">
            Årets tomtenisse är Greta! Hon bor vanligtvis i Tomteland och ser
            till att det är ordning och reda i tomteverkstaden, men hon är lite
            trött på att alltid vara den som är duktig och som gör rätt för sig.
            Greta vill ge sig i väg på ett riktigt äventyr där hon för en gångs
            skull får vara lite busig. Vad passar då bättre än att flytta hem
            till barn och busa där? När julafton kommer flyttar hon naturligtvis
            tillbaka för att hjälpa till i Tomteland, men fram tills dess ska
            hon se till att ha det så roligt hon bara kan tillsammans med
            barnen!
          </p>
        </div>
        <img
          src={require("../media/images/aretsnisse.jpg")}
          alt="Nissedörr"
          className="door-image"
        ></img>
      </div>
      <div className="grey-container">
        <div className="text-container">
          <a className="text-header">Exempel</a>
          <p className="text-item">
            Här kan ni lyssna på ljudfilen som ska spelas upp den 5e december.
            <br></br>
            Ni ser även ett utdrag från den tillhörande PDF-en.
          </p>
        </div>
        <div className="example-container">
          <audio controls className="example-object">
            <source src={require("../media/example.mp3")} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <img
            className="example-object"
            style={{ borderRadius: "20px" }}
            src={require("../media/images/exempel_uppdrag.png")}
            alt="Exempel på nisseuppdrag"
          ></img>
        </div>
      </div>
    </>
  );
};

export default ExampleBox;
